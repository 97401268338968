const darkTheme = {
  name: 'darkTheme',
  color: {
    headerAvatarHover: 'rgba(154, 134, 155, 0.35)',
    headerBackground: '#121016',
    headerSearch: '#2B2A2F',
    headerSearchBackground: '#1F1E23',
    headerSearchHover: '#727175',
    headerText: '#FFFFFF',
    sidebarBackground: '#18171b',
    sidebarDivider: '#333333',
    sidebarHeaderBackground: '#18171b',
    sidebarHeaderIcon: '#18171b',
    sidebarHeaderIconBackground: '#FFFFFF',
    sidebarHeaderText: '#FFFFFF',
    sidebarHover: '#27242C',
    sidebarText: '#A3A3A6',
    chatHeader: '#FFFFFF',
    chatText: '#A3A3A6',
    chatTextArea: '#22252A',
    chatTextAreaBorder: '#565856',
    chatDivider: '#333333',
    chatBackground: '#191b1e',
    chatMessageHover: '#1f2125',
    chatFormattingBarBackground: '#22252A',
    chatFormattingBarBorder: '#555555',
    chatFormattingBarIcon: '#A3A3A6',
    presenceActive: '#2BAC76',
    presenceAway: '#CFC3CF',
    badge: '#CD2553',
  },
};

export default darkTheme;
