const lightTheme = {
  name: 'lightTheme',
  color: {
    headerAvatarHover: 'rgba(0,0,0,.1)',
    headerBackground: '#F8F8FA',
    headerSearch: 'rgba(0,0,0,.1)',
    headerSearchBackground: '#EBEBED',
    headerSearchHover: 'rgba(0,0,0,.3)',
    headerText: '#383F45',
    sidebarBackground: '#F8F8FA',
    sidebarDivider: 'rgba(0,0,0,.1)',
    sidebarHeaderBackground: '#FFFFFF',
    sidebarHeaderIcon: '#FFFFFF',
    sidebarHeaderIconBackground: '#383F45',
    sidebarHeaderText: '#383F45',
    sidebarHover: '#FFFFFF',
    sidebarText: '#686D72',
    chatHeader: '#383F45',
    chatText: '#000000',
    chatTextArea: '#FFFFFF',
    chatTextAreaBorder: '#383F45',
    chatBackground: '#FFFFFF',
    chatDivider: '#DDDDDD',
    chatMessageHover: '#F7F7F7',
    chatFormattingBarBackground: '#f8f8f8',
    chatFormattingBarBorder: '#DDDDDD',
    chatFormattingBarIcon: '#383F45',
    presenceActive: '#2BAC76',
    presenceAway: '#CFC3CF',
    badge: '#CD2553',
  },
};

export default lightTheme;
